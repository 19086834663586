export const DEFAULT_GAME = {
  ingame: 'lobby-ingame',
  cardGame: 'lobby-game-bai',
  slots: 'lobby-slots',
  lotte: 'lotte',
  fishing: 'lobby-ban-ca',
  quickgame: 'lobby-quick-game',
  table: 'lobby-table-game',
  instant: 'lobby-toc-chien',
  nohu: 'lobby-nohu',
  keno: 'keno',
  cockfight: 'cockfight'
}
